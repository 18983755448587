import React, { useState } from 'react';
import { Link } from "react-router-dom";
import permissionService from '../services/permissionService';
import { Flex,Input,Button,Form,Badge, Card } from 'antd';
/*
Date: 2024-09-11 
Note: For test only
*/
var apiUrlTestToken = process.env.REACT_APP_SMART_TRACK_API_TEST;

const ReportTestToken = () => {
    // const params = useParams();
    const [receiveToken , setReceiveToken] = useState('');
    const [isUrlVisible, setIsUrlVisible] = useState(false);
    const onFinish = async (values) => {
        let resAllow = await getTokenFromSmartTrack(values.userId,values.RoleId);
        setReceiveToken(resAllow);

        setIsUrlVisible(true);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const cardStyles = {
        header: {
          backgroundColor: 'pink',
        },
    };
        // let url = 'http://localhost:3000/report01/' + receiveToken;
        const hostname = window.location.hostname;
        const protocol = window.location.protocol;
        let showUrl = protocol+hostname+'/report01';
        let urlRouteReport01 = '/report01/'+ receiveToken;
        return(
        <>
             <Flex wrap gap="middle" style={{ marginTop: 16 }} justify="center" >
                    <Flex vertical gap={3}>
                    <Badge.Ribbon text="Dev Mode" color="red">
                        <Card 
                            title="For team test only." 
                            size="small" 
                            styles={cardStyles}
                        >
                            {<span style={{ color: 'grey' }}>{apiUrlTestToken}</span>}
                        </Card>
                    </Badge.Ribbon>
                        <Form
                            name="basic"
                            labelCol={{
                            span: 8,
                            }}
                            wrapperCol={{
                            span: 16,
                            }}
                            style={{
                            maxWidth: 600,
                            }}
                            initialValues={{
                            remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                            label="UserID"
                            name="userId"
                            rules={[
                                {
                                required: true,
                                message: 'Please input user id!',
                                },
                            ]}
                            >
                            <Input />
                            </Form.Item>
                            
                            <Form.Item
                            label="RoleId"
                            name="RoleId"
                            rules={[
                                {
                                required: true,
                                message: 'Please input role!',
                                },
                            ]}
                            >
                                <Input />
                            </Form.Item>
                            
                            <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 8,
                            }}
                            >
                                <Button type="primary" htmlType="submit">
                                    Gen Link Report
                                </Button>
                            </Form.Item>
                        </Form>
                        <div>
                                {/* { isUrlVisible ? <a href={url}>{showUrl}/xxxx</a> : null} */}
                                { isUrlVisible ? <Link to={urlRouteReport01}>{showUrl}/token...</Link> : null}
                                
                        </div>
                        
                    </Flex>
             </Flex>
             
        </>
        )
    
}

const getTokenFromSmartTrack = async (userId,roleId) => {
    try{
            const response = await permissionService.getTestTokenFromSmartTrackApi(userId,roleId);
            return response.data.token;
    }catch(error){
            console.error('Error checkTokenPermission', error.code);
            return {code: "099", message: error.message};
    }
    
};

export default ReportTestToken;