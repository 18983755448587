import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom'; 
import ReportWorkingHour from './pages/reportWorkingHour';
import ReportTestToken from './pages/reportTestToken';


function App() {
  return (
    /*
    // Date: 2024-09-11 
    // Note: <ReportTestToken> For test only at line 17 !!
    */
    <Routes>
        {/* <Route path='/' element={<ReportWorkingHour />}/> */}
        <Route path='/report01/:id' element={<ReportWorkingHour />}/>
        <Route path='/ReportTestToken' element={<ReportTestToken />}/> 
    </Routes>
  );
}

export default App;